<script>
import moment from 'moment'
import serverConfig from '@/helpers/config';
import Swal from "sweetalert2";
import axios from 'axios';
import {
    required,
    helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: "surveyManager",
  props: [
    'ID',
    'data',
  ],
  setup() {
   
    moment.locale('it')

    let tk = 'Bearer '+localStorage.getItem('tk')
    return { tk, v$: useVuelidate() };
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
       
        item:{

          surveyDetails:{
              date:moment.unix(),
              dateStr:moment().format('YYYY-MM-DD'), 
              status: '', 
              response:'',
              note:'',
              ratedPower:0,
              createdBy:{}, 
          }           
        },



    };
  },
    validations: {
      data: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
  mounted() {
    //this.getItem()
    this.item.surveyDetails = this.data
  },

  methods: {
    convertDate(val){
        this.item.surveyDetails.date =  moment(val).unix()
        this.updateItem()
    },
    responseConfirm(){
      if (this.item.surveyDetails.response == 'positivo'){
       this.item.surveyDetails.status = 'fatto'
      }
      this.updateItem()
    },
    updateItem(){
       // this.submitted = true;
        //this.v$.$touch();
       
        axios.put(`${this.UrlServer}projects/${this.ID}`, this.item, {headers: { authorization:this.tk}} )
          .then(()=>{
            this.$emit('getParent')
          })
          .catch((error)=>{
            if (error == "Error: Request failed with status code 401")
            {
              console.log(error)
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
             
          });
      },
  },

};
</script>

<template>
    <form action="">
        <div class="row mb-3">
            <div class="col-lg-2">
                <label for="nameInput" class="form-label">Stato</label>
                <select class="form-select"  placeholder="Seleziona stato sopralluogo" data-trigger id="sourcefield" v-model="item.surveyDetails.status"  @change="updateItem">
                    <option value="">Seleziona stato sopralluogo</option>
                    <option value="in corso">In corso</option>
                    <option value="fatto">Fatto</option>
                    <option value="problemi">Problemi</option>
                </select>                      
            </div>
            <div class="col-lg-1">
                <label for="dateInput" class="form-label">Data</label>
                <input type="date" class="form-control" id="dateInput" v-model="item.surveyDetails.dateStr"  @change="convertDate(item.surveyDetails.dateStr)">
            </div>
            <div class="col-lg-2">
                <label for="ratedPower" class="form-label">Potenza installabile prevista</label>
                <div class="input-group">
                <span class="input-group-text" id="inputGroup-sizing-default">Kw</span>
                <input type="number" maxlength="20" class="form-control width-20" id="ratedPowerInput" v-model="item.surveyDetails.ratedPower"  @change="updateItem" >
              </div>                  
            </div>      
            <div class="col-lg-2">
              <label for="responseInput" class="form-label">Esito</label>
                <select class="form-select"  placeholder="Seleziona Esito sopralluogo" data-trigger id="responseInput" v-model="item.surveyDetails.response"  @change="responseConfirm">
                    <option value="">Seleziona Esito sopralluogo</option>
                    <option value="positivo">Positivo</option>
                    <option value="negativo">Negativo</option>    
                </select>                
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-6">
              <label for="responseMSGInput" class="form-label">Note sopralluogo</label>
                  <textarea class="form-control" id="responseMSGInput" rows="3" placeholder="Inserisci eventuali note sul sopralluogo" v-model="item.surveyDetails.note" @blur="updateItem"></textarea>        
                             
            </div>

            <div class="col-lg-3">          
            </div>      
            <div class="col-lg-3">
            </div>
        </div>
    </form>
</template>
