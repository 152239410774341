<script>
import { ref } from "vue";
import { mapActions } from "vuex";

import surveyManager from "@/components/widgets/surveyManager";
import documentManagerUT from "@/components/widgets/projects/documentManagerUT";


import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import moment from 'moment'
import serverConfig from '@/helpers/config';

import Swal from "sweetalert2";
import axios from 'axios';
import {
    required,
    helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  page: {
    title: "Gestione Documentazione Progetto",
    meta: [{ name: "description", content: appConfig.description }],
  },
  setup() {
    let files = ref([]);
    moment.locale('it')
    let tk = 'Bearer '+localStorage.getItem('tk')

    return { files, tk, v$: useVuelidate() };
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        showMainCustomer:false,
        showMainProgect:true,
        showMainSystems:false,
        showMainSurvey:true,
        showMainAdmin:false,
        showMainDocuments:false,
        showAmmProgect:false,
        showTechProgect:false,
        showAdvancedProgect:false,
        title: "Gestione Sopralluogo progetto",
        data:{
          source:'',
          customerCategory:'',
          systemCategory:'',
          systemSubCategory:'',
          fiscalBenefit:'',
          surveyDetails:{
              date:moment.unix(),
              dateStr:moment().format('YYYY-MM-DD'), 
              status: '', 
              response:'',
              note:'',
              createdBy:{}, 
          },
          accountingDetails:{
              date:moment.unix(),
              dateStr:moment().format('YYYY-MM-DD'), 
              status: '', 
              response:'',
              note:'',
              createdBy:{}, 
          },            
        },
        optionsFiscalBenefit:[],
        optionsFSPrivate:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'conto_termico', label: 'Conto termico' },
          { value: 'detrazione_fiscale', label: 'Detrazione fiscale' },
        ],
        optionsFSBusiness:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'credito_imposta', label: "Credito d'imposta" },
          { value: 'sconto_in_fattura', label: 'Sconto in fattura' },
        ],
        optionsFSPA:[
          { value: 'non_previsto', label: 'Non previsto' },
        ],
        optionsSystems:[],
        optionsSystemsACS:[
          { value: '', label: 'Seleziona sottocategoria ACS' },
          { value: 'scalda_acqua_in_pdc', label: 'Scalda acqua in pompa di calore' },
          { value: 'solare_termico', label: 'Solare Termico' },
        ],
        optionsSystemsClima:[
          { value: '', label: 'Seleziona sottocategoria Climatizzazione' },
          { value: 'area_area', label: "Area area" },
          { value: 'area_acqua', label: 'Area acqua' },
          { value: 'rap', label: 'R.A.P' },
          { value: 'termo_idraulica', label: 'Termo idraulica' },
          { value: 'vrv', label: 'VRV' },
        ],
        optionsSystemsED:[
          { value: '', label: 'Seleziona sottocategoria Edilizia' },
          { value: 'coibentazione_mc', label: "Coibentazione Manti Ceramic" },
          { value: 'coibentazione_eps', label: 'Coibentazione EPS' },
          { value: 'impiantistica_idrica', label: 'Impiantistica idrica' },
          { value: 'ristrutturazioni', label: 'Ristrutturazioni' },
        ],
        optionsSystemsEL:[
          { value: '', label: 'Seleziona sottocategoria Energia elettrica' },
          { value: 'fotovoltaico', label: "Fotovoltaico" },
          { value: 'sistemi_di_accumulo', label: 'Sistemi di accumulo' },
          { value: 'colonnine_di_rc', label: 'Colonnine di ricarica' },
          { value: 'impiantistica_elettrica', label: 'Impiantistica elettrica' },
        ],
        checkCustomerID:false,
        checkSystem:false,
      n_required:0,
      n_true:0,
      r:0,

      items: [
        {
          text: "Elenco progetti",
          href: "/projects/list-view",
        },
        {
          text: "Dettaglio Progetto",
          href: `/projects/detail/${this.$route.params.id}`,
        },
        {
          text: "Sopralluogo",
          active: true,
        },
      ],
      galleryItems:[
      {
        "src": "https://storage.googleapis.com/sunerp40.appspot.com/store/images/Climatizzatore-Condizionatore-Daikin-Bluevolution-Inverter-serie-extra-big-311656-663.jpg?GoogleAccessId=firebase-adminsdk-qe5pp%40sunerp40.iam.gserviceaccount.com&Expires=1715003265&Signature=cCyi0T%2FdaG5BZI4vtXeSODkAQHOmQoilwb12rsQrWQkLqe9HH4Qh73c9vRGZTvu9KxGCM%2FMpFi9l4i8LCiiylbslRpWZCUiuFfeUcWSTt%2B1O5fAzC1Dis%2FgKADCYusyXj4juNXxyBY3CgaTFy%2Fz1Ri9U3DibA2jH6eB76O1frEERqygVob9nMQzO11FQO7lqmmm0xjsQzIcYzffY7aMg%2F8QrYHOvZe4yAt01j5QGUkaqEL1vdlhwfnuw7XeWLHqoFtwUjLjAiLNR0REDRlDnwBthkdT%2B%2B4yQEcYmnuy0ixL%2BZGkzZDVY11rIr7ak7Gr%2BXA0w7CQumQ8abiPSgLdQtw%3D%3D",
        "thumbnail": "https://storage.googleapis.com/sunerp40.appspot.com/store/images/Climatizzatore-Condizionatore-Daikin-Bluevolution-Inverter-serie-extra-big-311656-663.jpg?GoogleAccessId=firebase-adminsdk-qe5pp%40sunerp40.iam.gserviceaccount.com&Expires=1715003265&Signature=cCyi0T%2FdaG5BZI4vtXeSODkAQHOmQoilwb12rsQrWQkLqe9HH4Qh73c9vRGZTvu9KxGCM%2FMpFi9l4i8LCiiylbslRpWZCUiuFfeUcWSTt%2B1O5fAzC1Dis%2FgKADCYusyXj4juNXxyBY3CgaTFy%2Fz1Ri9U3DibA2jH6eB76O1frEERqygVob9nMQzO11FQO7lqmmm0xjsQzIcYzffY7aMg%2F8QrYHOvZe4yAt01j5QGUkaqEL1vdlhwfnuw7XeWLHqoFtwUjLjAiLNR0REDRlDnwBthkdT%2B%2B4yQEcYmnuy0ixL%2BZGkzZDVY11rIr7ak7Gr%2BXA0w7CQumQ8abiPSgLdQtw%3D%3D",
        "w": 1200,
        "h": 900
      },
      {
        "src": "https://storage.googleapis.com/sunerp40.appspot.com/store/images/Unita-Interna-a-Parete-Daikin-Bluevolution-serie-STYLISH-WHITE-7-extra-big-233192-038.jpg?GoogleAccessId=firebase-adminsdk-qe5pp%40sunerp40.iam.gserviceaccount.com&Expires=1715003265&Signature=LaQwAZPMwloItv8%2FaBnu8rJm7zyVZab0c2%2FWm1mG6Wb3i7RwTqUUXmbYRaduVdsbydCW1HmKbnVudeCkwT%2Fk%2B5aKtHPHW9R%2FwDlS7kVjk4mhwKg9CKE9MuOC72hwJRqY1I5OR4Mm3t7WJfRCJPHURgaOse8URwdJ2N%2BnjHCBdhVu8apt3C2H3ewLmy%2F4v%2BFmFN553of0YbJ%2B5uT0zt9QzGgBT6qELdtE3Z0Opew%2F9NXn20qdPLYC771DrKn8kyIylGMcTVuy6v%2Bt3IhHThX2aNIhfxBBmi2lEbVJ6mh54h7f7l9aQ3ZWDI1KSYBfF1%2BCyMfx8LzPt2JMPrbhDLbU6w%3D%3D",
        "thumbnail": "https://storage.googleapis.com/sunerp40.appspot.com/store/images/Unita-Interna-a-Parete-Daikin-Bluevolution-serie-STYLISH-WHITE-7-extra-big-233192-038.jpg?GoogleAccessId=firebase-adminsdk-qe5pp%40sunerp40.iam.gserviceaccount.com&Expires=1715003265&Signature=LaQwAZPMwloItv8%2FaBnu8rJm7zyVZab0c2%2FWm1mG6Wb3i7RwTqUUXmbYRaduVdsbydCW1HmKbnVudeCkwT%2Fk%2B5aKtHPHW9R%2FwDlS7kVjk4mhwKg9CKE9MuOC72hwJRqY1I5OR4Mm3t7WJfRCJPHURgaOse8URwdJ2N%2BnjHCBdhVu8apt3C2H3ewLmy%2F4v%2BFmFN553of0YbJ%2B5uT0zt9QzGgBT6qELdtE3Z0Opew%2F9NXn20qdPLYC771DrKn8kyIylGMcTVuy6v%2Bt3IhHThX2aNIhfxBBmi2lEbVJ6mh54h7f7l9aQ3ZWDI1KSYBfF1%2BCyMfx8LzPt2JMPrbhDLbU6w%3D%3D",
        "w": 1200,
        "h": 900
      },
      {
        "src": "https://storage.googleapis.com/sunerp40.appspot.com/store/images/SANNA%20E%20PIRAS%20SALES%20POLICY%202023.pdf?GoogleAccessId=firebase-adminsdk-qe5pp%40sunerp40.iam.gserviceaccount.com&Expires=1715003265&Signature=PYxDxsXMCPYLW2n9FT6fudXPn2OM5g%2FPcxzbGc0WsEaBwPbpkC%2BrXUNG1puRqMSccCh13DC3rxNSHxtw%2B2lSiUmN%2Bv4b4HCSDBNNAM2thXgan3BnCeiSyBCRAppiAHeAhojih0MpbmQ96fII9n51K6FSC1Ss0lAkejTdiHm1%2BPqTyTvbll51ymw29U%2BjkFmcSuo6pK82HAPznDbujsYGTaBSo1nhO834uWC3PSfoZrzlOFeETDsaZWDGayD4BqZIGuwBn1hSbXsA5zRAfKuvIKwsZDBm5f5%2FD3PXeX7wt8x0GdtiFfXvri1A3R3ifYu8cLBxQxMl3r1om7DGrTbM0Q%3D%3D",
        "thumbnail": "https://storage.googleapis.com/sunerp40.appspot.com/store/images/SANNA%20E%20PIRAS%20SALES%20POLICY%202023.pdf?GoogleAccessId=firebase-adminsdk-qe5pp%40sunerp40.iam.gserviceaccount.com&Expires=1715003265&Signature=PYxDxsXMCPYLW2n9FT6fudXPn2OM5g%2FPcxzbGc0WsEaBwPbpkC%2BrXUNG1puRqMSccCh13DC3rxNSHxtw%2B2lSiUmN%2Bv4b4HCSDBNNAM2thXgan3BnCeiSyBCRAppiAHeAhojih0MpbmQ96fII9n51K6FSC1Ss0lAkejTdiHm1%2BPqTyTvbll51ymw29U%2BjkFmcSuo6pK82HAPznDbujsYGTaBSo1nhO834uWC3PSfoZrzlOFeETDsaZWDGayD4BqZIGuwBn1hSbXsA5zRAfKuvIKwsZDBm5f5%2FD3PXeX7wt8x0GdtiFfXvri1A3R3ifYu8cLBxQxMl3r1om7DGrTbM0Q%3D%3D",
        "w": 1200,
        "h": 900
      },
      {
        "src": "https://storage.googleapis.com/sunerp40.appspot.com/store/images/Contattaci-IMP.jpg?GoogleAccessId=firebase-adminsdk-qe5pp%40sunerp40.iam.gserviceaccount.com&Expires=1715003265&Signature=ADOg37Y7lP44WKE2DPVs5JG07Vf4J3VHLJxZAnU6MRDSyp%2Fnc8%2Fm7YQ0mSN5mRis0UhOQcNl4%2BG6t0l0SCq2%2Biqk%2BR4l5vfOYVwRs5Yascn2b58JrHJooBp4ifhZB3buDsVOf03MzVoNu73AyXW%2FSXku66Xh%2BHTTA6dsWzmKw6ihqU6vYEZrrJ3%2BUgDTYehzGrm8Vl%2BzusI78LCLO6vsjCnPaHS45yLb0K94W2ewGIEPwVaj1q8lND7%2BN6ALiAzGg4gs2pxOYOl6B6Bb84w31i924icr4peOe%2Fb4VNDzmNBzt%2Bu%2FVHFxOtOcJYrdVTyXgbmAmCtwlyqE4Y%2BEQCToOQ%3D%3D",
        "thumbnail": "https://storage.googleapis.com/sunerp40.appspot.com/store/images/Contattaci-IMP.jpg?GoogleAccessId=firebase-adminsdk-qe5pp%40sunerp40.iam.gserviceaccount.com&Expires=1715003265&Signature=ADOg37Y7lP44WKE2DPVs5JG07Vf4J3VHLJxZAnU6MRDSyp%2Fnc8%2Fm7YQ0mSN5mRis0UhOQcNl4%2BG6t0l0SCq2%2Biqk%2BR4l5vfOYVwRs5Yascn2b58JrHJooBp4ifhZB3buDsVOf03MzVoNu73AyXW%2FSXku66Xh%2BHTTA6dsWzmKw6ihqU6vYEZrrJ3%2BUgDTYehzGrm8Vl%2BzusI78LCLO6vsjCnPaHS45yLb0K94W2ewGIEPwVaj1q8lND7%2BN6ALiAzGg4gs2pxOYOl6B6Bb84w31i924icr4peOe%2Fb4VNDzmNBzt%2Bu%2FVHFxOtOcJYrdVTyXgbmAmCtwlyqE4Y%2BEQCToOQ%3D%3D",
        "w": 1200,
        "h": 900
      }        
      ]



    };
  },
    validations: {
      data: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
  mounted() {
    this.getItem()
  },

  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3500,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                    this.$router.push('/')          
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },
    convertTmpToDate(val){
        return moment.unix(val).format('LLL')
    },
    filterOptions(val){
        switch(val){
          case "azienda":
            this.optionsFiscalBenefit = this.optionsFSBusiness
            break;
          case "privato":
            this.optionsFiscalBenefit = this.optionsFSPrivate
            break;
          case "pubblico":
            this.optionsFiscalBenefit = this.optionsFSPA
            break;
        }        
    },    
    filterOptionsSystem(val){
      
        switch(val){
          case "acqua_calda_sanitaria":
            this.optionsSystems = this.optionsSystemsACS
            break;
          case "climatizzazione":
            this.optionsSystems = this.optionsSystemsClima
            break;
          case "edilizia":
            this.optionsSystems = this.optionsSystemsED
            break;
          case "energia_elettrica":
            this.optionsSystems = this.optionsSystemsEL
            break;
        }   
         
    },    
    deleteRecord(ele) {
      ele.target.parentElement.parentElement.remove();
    },
    cambiaOpzioneBeneficio(){
        let privato = [
          {"value":"sconto-fattura","text":"Sconto in fattura"},
          {"value":"conto-termico","text":"Conto Termico"},
          {"value":"nessuno","text":"Nessuno"},
        ]
        let azienda = [
          {"value":"credito-imposta","text":"Credito d'imposta"},
          {"value":"sconto-fattura","text":"Sconto in fattura"},
          {"value":"nessuno","text":"Nessuno"},
        ]    
       
      if (this.project.tipologiaCommessa =='privato'){
        this.opzioneBeneficio = privato
      } else {
        this.opzioneBeneficio = azienda
      }   
    },
    submitForm(){
     
      document.getElementById("genDataForm").submit();
    },
    getParent(){
      this.getItem()
    },
    checkDocuments(array){
      if (array){
        let count = array.length
        let checkTrue = array.filter(function(el){
          return el.exist == true
        })
        
        if (count > checkTrue.length ){
          this.r = count - checkTrue.length
        } 
        this.n_required = count
        this.n_true = checkTrue

        return true
      } else {
        return false
      }

    },
    getItem() {
      this.data = {}
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}projects/${this.$route.params.id}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data) {
              this.data = response.data[0]
              if (!this.data.accountingDetails.contract){
                this.data.accountingDetails.contract = false
              }
              if(!this.data.customerDetails){
                this.data.customerDetails = this.customerDetails
              }

              if (response.data[0].customerCategory){
                  this.filterOptions(response.data[0].customerCategory)
                  this.filterOptionsSystem(response.data[0].systemCategory)

                  if (!response.data[0].customerID){
                      this.checkCustomerID = false
                      //this.showMainCustomer = true
                  } else {
                      this.checkCustomerID = true
                      //this.showMainCustomer = false                    
                  }
                  if (!response.data[0].systems || response.data[0].systems.length == 0){
                      this.checkSystem = false
                      //this.showMainSystems = true
                  } else {
                      this.checkSystem = true
                      //this.showMainSystems = false                    
                  }
                  if (!response.data[0].surveyDetails){
                    this.data.surveyDetails = {
                      date:moment.unix(),
                      dateStr:moment().format('YYYY-MM-DD'), 
                      status: '', 
                      response:'',
                      note:'',
                      createdBy:{},                       
                    }
                  }
                  if (!response.data[0].accountingDetails){
                    this.data.accountingDetails = {
                      date:moment.unix(),
                      dateStr:moment().format('YYYY-MM-DD'), 
                      status: '', 
                      response:'',
                      note:'',
                      createdBy:{},                       
                    }
                  }
                  if (!response.data[0].documentsRequired){
                    this.data.documentsRequired = []
                  }                  
              }
              
          }        
          
      })
    },
    init(){
      this.axiosInterceptor()
      axios.post(`${this.UrlServer}worksites/register`, this.worksite, {headers: { authorization:this.tk}}).then((response) => {
        if(response.data) {
          this.getItem()
          Swal.fire( this.$t("t-msg-worksite-tab-created-title") , this.$t("t-msg-worksite-tab-created-content"), "success");
        }
      })
    },
    getDocs() {
      this.axiosInterceptor()
        axios.get(`${this.UrlServer}settings/all/documents/list/${this.data.systemSubCategory}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data) {
            this.data.documentsRequired = response.data
            this.updateItem()
          }        
          
      })
    },
    updateItem(){
       // this.submitted = true;
        this.v$.$touch();
        this.data.createdBy = this.datastore.auth.currentUser
        this.axiosInterceptor()
        axios.put(`${this.UrlServer}projects/${this.$route.params.id}`, this.data, {headers: { authorization:this.tk}} )
          .then(()=>{
            
          })
      },
    updateDoc(tag){
      let val = document.getElementById(tag).value
      console.log(val)

    },
    updateW(){
     this.axiosInterceptor()
     axios.put(`${this.UrlServer}worksites/${this.data.worksite[0]._id}`, this.data.worksite[0], {headers: { authorization:this.tk}})
     .then(() => {
     })
   }, 
  },
  components: {
    surveyManager,
    documentManagerUT,
    Layout,
    PageHeader,
    
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1" >
            <template v-if="data">
                <span class="mdi mdi-24px mdi-account-details pr-5 "></span>
                <template v-if="data.customerDetails">{{ data.customerDetails.ragSoc }}</template>
                 - Localizzazione: 
                 <template v-if="data.worksite!=null">
                     <template v-if="data.worksite.length>0">
                        {{ data.worksite[0].address }} {{ data.worksite[0].city }}
                     </template>
                </template>
            </template>
            </h4>
            <div class="flex-shrink-0">
                <template v-if="data">
                    Tipo impianto: 
                   <strong> {{ data.systemSubCategory }}</strong>
                </template>
            </div>
          </div>
        </div>
      </div>
    </div>     
    <div class="row" v-if="data.surveyDetails">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
           
            <div class="card-title mb-0 flex-grow-1">

                <div class="row">
                  <div class="col-lg-3">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        
                        <span class="mdi mdi-24px mdi-account-hard-hat pr-5 "></span>
                        <template v-if="data.surveyDetails.status=='fatto' && data.surveyDetails.response!='negativo'">
                          <span class="mdi mdi-24px mdi-check pr-5 "></span>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        </template>
                        <span class="pr-5">Sopralluogo
                        <template v-if="data.surveyDetails.response!=''">
                          con esito
                          <template v-if="data.surveyDetails.response=='positivo'">
                          <span class="mdi mdi-24px mdi-check pr-5 positive"> {{data.surveyDetails.response}}</span> 
                          </template>
                          <template v-else>
                            <span class="mdi mdi-24px mdi-alert pr-5 negative"> {{data.surveyDetails.response}}</span> 
                          </template>
                         
                        </template>  
                       
                        </span> 
                      </div>

                    </div>        
                  </div>   
                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                    </div>        
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
              &nbsp;
                <label for="FormSelectSystem" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectSystem"
                  v-model="showMainSurvey"
                />
              </div>
            </div>
          </div>
         <template v-if="data.surveyDetails">
          <div class="card-body" v-if="showMainSurvey">
            <surveyManager :ID="$route.params.id" :data="data.surveyDetails" v-on:getParent="getParent()" />
          </div>
         </template>
        </div>
      </div>
    </div>
    <div class="row" v-if="data.surveyDetails">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
           
            <div class="card-title mb-0 flex-grow-1">

                <div class="row">
                  <div class="col-lg-3">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        
                        <span class="mdi mdi-24px mdi-file-document pr-5 "></span>
                        <span class="pr-5">Documentazione 

                       
                        </span> 
                      </div>

                    </div>        
                  </div>   
                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                    </div>        
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
              &nbsp;
                <label for="FormSelectSystem" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectSystem"
                  v-model="showMainDocuments"
                />
              </div>
            </div>
          </div>
         <template v-if="data.surveyDetails">
          <div class="card-body" v-if="showMainDocuments">
            <template v-if="data">
              <template v-if="data.customer!=null">
                <template v-if="data.customer.length>0">
                  <template v-if="!data.customer[0].nomeFascicolo">
                      <b-alert show variant="danger" class="mb-25">
                        <strong> "Nome Fascicolo" non individuato: </strong> aggiorna l'anagrafica cliente, per favore.
                      </b-alert>
                    </template>      
                    

                  <documentManagerUT 
                    :url="`${this.UrlServer}storage/by/project`"
                    :projectID="data._id" 
                    :data="data" 
                    :bucket="data.customer[0].nomeFascicolo + data.year.toString() + '/' + data.systemSubCategory+'/'" 
                    type="doc-sopralluogo"
                    refDirectory="sopralluogo"
                    fileType="multiple" 
                    fileAccept="*" 
                    title="Documentazione impianto"
                    v-on:getParent="getParent()"/>
                </template>
              </template>
            </template>           
          </div>
         </template>
        </div>
      </div>
    </div>
  </Layout>
</template>
